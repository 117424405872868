import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";


import { media } from '../components/layoutComponents';

const Logo = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 80%;

  ${media.ms`
      width: 280px;
  `}

  ${media.sm`
      width: 300px;
  `}

`

const Header = ({ siteTitle, className }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          },
        }
      },
    },
  `)

  return (
    <header className={className}>
      <Logo>
        <Img
          fluid={data.logo.childImageSharp.fluid}
          title="Blinking Cursor"
          alt="Blinking Cursor"
        />
      </Logo>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledHeader = styled(Header)`
  color: var(--white);
  background: var(--primary-color);
  padding: 1rem 0;
  margin-bottom: 1rem;
  h1 {
    margin: 0;
  }
`

export default StyledHeader
