import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TwitterButton = styled.a`
  margin-right: 2rem;
`

const FacebookButton = styled.a`
  margin-right: 2rem;
`

const InstagramButton = styled.a`
`

const Buttons = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fixed(width: 28) {
            ...GatsbyImageSharpFixed
          },
        }
      },
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fixed(width: 28) {
            ...GatsbyImageSharpFixed
          },
        }
      },
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fixed(width: 28) {
            ...GatsbyImageSharpFixed
          },
        }
      }
    },
  `)

  return (
    <React.Fragment>
      <TwitterButton href="https://twitter.com/cursorblink">
        <Img
          fixed={data.twitter.childImageSharp.fixed}
          title="Visit us on Twitter"
          alt="Visit us on Twitter"
        />
      </TwitterButton>
      <FacebookButton href="https://www.facebook.com/Blinking-Cursor-110233204118406">
        <Img
          fixed={data.facebook.childImageSharp.fixed}
          title="Visit us on Facebook"
          alt="Visit us on Facebook"
        />
      </FacebookButton>
      <InstagramButton href="https://www.instagram.com/cursorblink/">
        <Img
          fixed={data.instagram.childImageSharp.fixed}
          title="Visit us on Instagram"
          alt="Visit us on Instagram"
        />
      </InstagramButton>
    </React.Fragment>
  )
}

export default Buttons
