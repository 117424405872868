import React from "react"
import styled from "styled-components"
import { media } from '../components/layoutComponents';

import SocialButtons from "../components/socialbuttons"


const SocialButtonContainers = styled.div`
    margin: 0 auto 0 -150px;
    width: 300px;
    position: fixed;
    left: 50%;
    bottom: 6%;
    font-size: 0.75rem;
    text-align: center;

    ${media.md`
      bottom: 3%;
    `}
`

const Copyright = styled.div`
    position: fixed;
    bottom: 2%;
    
    font-size: 0.75rem;

    margin: 0 auto 0 -150px;
    width: 300px;
    position: fixed;
    left: 50%;
    text-align: center;

    ${media.md`
      margin: 0;
      left: auto;
      text-align: right;
      right: 2.5rem;
      bottom: 3.75%;
    `}
`

const Footer = ({ className }) => (
  <footer className={className}>
    <SocialButtonContainers>
      <SocialButtons />
    </SocialButtonContainers>
    <Copyright>
      © {new Date().getFullYear()} Blinking Cursor LLC
    </Copyright>
  </footer>
)

const StyledFooter = styled(Footer)`
  color: #fffaff;
  background: var(--primary-color);
  padding: 1rem 0;
`

export default StyledFooter
