import { createGlobalStyle } from "styled-components"
import bg from "../images/bg-min.jpg"


import { media } from '../components/layoutComponents';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Space+Mono');

  html {
    box-sizing: border-box;
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background: url(${bg}) no-repeat center center fixed; 
    background-size: cover;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Space Mono';
  }


  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    color: #fff;
    font-size: 0.85rem;
    margin-bottom: 1rem;
    font-weight: bold;

    ${media.xs`
      font-size: 0.95rem;
    `}

    ${media.ms`
      font-size: 1.05rem;
    `}

    ${media.sm`
      font-size: 1.2rem;
    `}

    ${media.md`
      font-size: 1.4rem;
    `}
  }

  li {
      margin-bottom: 1rem;
  }

  a {
    color: #ccc;
    text-decoration: none;
    transition: all 500ms ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }

  .message {
    color: #fff;

    width: 280px;

    ${media.xs`
      width: 320px;
    `}

    ${media.ms`
      width: 340px;
    `}

    ${media.sm`
      width: 24rem;
    `}

    ${media.md`
      width: 28rem;
    `}
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }

  .viz {
    transition: opacity 1000ms ease-in-out;
  }

  .links {
    margin-top: 1.5rem;
    padding: 1rem;
    /* border-top: 0.5px solid #ccc; */
    /* padding-top: 1.5rem; */
  }

  .mediaLink {
    display: flex;
    font-size: 0.85rem;
    .mediaContent {
      margin-left: 1rem;
    }

    .mediaImg {
      width: 48px;
      flex: none;
    }
  }

  p.app-title {
    font-weight: bold;
    color: #fff;
    margin-bottom: 0.25rem;
    ${media.sm`
      font-size: 1rem;
    `}
  }

  p.app-desc {
    font-weight: normal;
    color: #ccc;
    font-size: 0.75rem;
  }
`
